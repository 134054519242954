import React, { FunctionComponent } from 'react';
import { GatsbyLinkProps, withPrefix } from 'gatsby';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import classNames from 'classnames';
import { Grid, GridProps as BaseGridProps } from '@tf/design-system-react';
import { animation } from '../../assets/styles/theme';

import * as styles from './ButtonLink.module.scss';

interface Props extends GatsbyLinkProps<{}> {
  variant: 'outlined' | 'contained';
  color?: 'primary' | 'secondary';
  fullWidth?: boolean;
  openInNewTab?: boolean;
  activeClassName?: string;
  alignment?: 'left' | 'center' | 'right';
}

const ButtonLink: FunctionComponent<Props> = ({
  className,
  to,
  children,
  variant,
  color,
  fullWidth = false,
  openInNewTab = false,
  activeClassName = '',
  alignment = 'left',
}) => {
  const btnClass = classNames(
    className ?? '',
    styles.button,
    { [styles.outlined]: variant === 'outlined' },
    { [styles.contained]: variant === 'contained' },
    { [styles.primary]: color === 'primary' },
    { [styles.secondary]: color === 'secondary' },
  );

  const getAlignment = (
    alignment: 'left' | 'center' | 'right',
  ): BaseGridProps['justifyContent'] => {
    switch (alignment) {
      case 'center':
        return 'center';
      case 'right':
        return 'flex-end';
      default:
        return 'flex-start';
    }
  };

  const isExternalLink = to.indexOf('http') !== -1;

  const button = isExternalLink ? (
    <a
      href={to}
      target={'_blank'}
      className={`${btnClass} ${fullWidth ? styles.fullWidth : ''}`}
      rel="noreferrer"
    >
      {children}
    </a>
  ) : (
    <AniLink
      to={withPrefix(to)}
      target={openInNewTab ? '_blank' : '_self'}
      activeClassName={activeClassName}
      className={`${btnClass} ${fullWidth ? styles.fullWidth : ''}`}
      cover
      direction={animation.curtainDirection}
      bg={animation.curtainBackground}
      duration={animation.curtainDuration}
    >
      {children}
    </AniLink>
  );

  return (
    <Grid container justifyContent={getAlignment(alignment)}>
      <Grid item className={`${fullWidth ? styles.fullWidth : ''}`}>
        {button}
      </Grid>
    </Grid>
  );
};

export default ButtonLink;
