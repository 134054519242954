import React, { useState } from 'react';

import {
  Button,
  Container,
  Controller,
  FormHelperText,
  Grid,
  hookformResolver,
  InputLabel,
  schemaValidator,
  TextField,
  useForm,
} from '@tf/design-system-react';

import Template from '../components/template/Template';
import BackGroundCover from '../components/background-cover/BackGroundCover';
import HelmetWrapper from '../components/helmet-wrapper/HelmetWrapper';
import ButtonLink from '../components/button-link/ButtonLink';

import * as styles from './reset.module.scss';
import axios from 'axios';
import { getAxiosErrorMessage } from '../utils/util';
import { seo } from '../common/seo';
import { copies } from '../common/copies';

interface FormDataInterface {
  email?: string;
}

const LoginPage = (): JSX.Element => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const mode = 'light';

  const schema = schemaValidator.object().shape({
    email: schemaValidator
      .string('Enter a email address')
      .required('Email is required')
      .email('Enter a valid email'),
  });

  const defaultValues = {
    email: '',
  };

  const { handleSubmit, control, errors } = useForm({
    resolver: hookformResolver(schema),
    defaultValues,
  });

  function setMessage(success: boolean, errorMsg: string | undefined): void {
    setIsSuccessful(success);
    errorMsg === null ? setErrorMessage(errorMsg) : setErrorMessage(errorMsg);
  }

  const onSubmit = async (data: FormDataInterface): Promise<void> => {
    axios
      .post('/tradefloor/public_api/forgot', {
        ...data,
        broker: 'OPEN_MARKETS',
      })
      .then((res) => {
        if (res.status === 200) {
          setMessage(true, undefined);
        } else {
          console.error(res);
          setMessage(false, getAxiosErrorMessage(res));
        }
      })
      .catch((error) => {
        setMessage(false, getAxiosErrorMessage(error.response));
      });
  };

  return (
    <Template fullScreen={true}>
      <HelmetWrapper
        metadata={{
          seoMetaTitle: seo.password_recover.meta_title,
          seoMetaDescription: seo.password_recover.meta_description,
          seoNoIndex: null,
          seoOgTitle: seo.password_recover.og_title,
          seoOgDescription: seo.password_recover.og_description,
          seoOgType: seo.password_recover.og_type,
          seoOgImage: seo.password_recover.og_image,
        }}
      />
      <div
        data-component-name="Reset"
        data-component-library="ot-kontent-gatsby"
        className={styles.reset}
      >
        <BackGroundCover
          className={styles.headerContainer}
          mode={mode}
          background={
            'transparent linear-gradient(180deg, #FFFFFF 0%, #F8DFEB 100%) 0% 0% no-repeat padding-box'
          }
          withCurve={true}
          curveColor={'#FFFFFF4D'}
          withDecorator={true}
          decoratorColor={'#FFFFFF4D'}
          addHeaderLogo
          animatedDecorator={true}
        >
          <Container>
            <Grid container justifyContent="center" alignContent="center" direction="column">
              <Grid item className={styles.header}>
                {copies.recover_password}
              </Grid>

              <Grid item className={styles.divider}>
                <hr />
              </Grid>

              {isSuccessful ? (
                <>
                  <Grid item className={styles.intro}>
                    <p>{copies.recovery_success_text}</p>
                  </Grid>

                  <Grid item>
                    <ButtonLink
                      variant={'outlined'}
                      color={'primary'}
                      to="/"
                      alignment={'center'}
                    >
                      {copies.recovery_success_cta}
                    </ButtonLink>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item className={styles.intro}>
                    <p>{copies.recover_password_intro}</p>
                  </Grid>

                  <Grid item>
                    <form className={styles.resetForm} noValidate>
                      <Grid container direction="column" spacing={4}>
                        <Grid item>
                          <InputLabel>Email address</InputLabel>
                          <Controller
                            as={<TextField fullWidth id="email" variant="outlined" />}
                            name="email"
                            type="email"
                            control={control}
                            helperText={errors?.email?.message}
                            error={(errors?.email?.message?.length ?? 0) > 0}
                            fullWidth
                          />
                        </Grid>

                        <Grid item>
                          <Button
                            onClick={handleSubmit(onSubmit)}
                            variant="contained"
                            color="primary"
                            type="submit"
                          >
                            {copies.recovery_password_cta}
                          </Button>
                        </Grid>

                        {errorMessage && (
                          <Grid item>
                            <FormHelperText error className={styles.formHelperText}>
                              {errorMessage}
                            </FormHelperText>
                          </Grid>
                        )}
                      </Grid>
                    </form>
                  </Grid>
                </>
              )}
            </Grid>
          </Container>
        </BackGroundCover>
      </div>
    </Template>
  );
};

export default LoginPage;
